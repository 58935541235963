
import { Options, Prop, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Question from "@/components/Pages/TheoryQuestions/Question.vue";
import { namespace } from "s-vuex-class";
import { mixins } from "vue-class-component";
import NavigationMixin from "@/mixins/NavigationMixin";
import QuestionAnswersMixin from "@/mixins/TheoryQuestions/QuestionAnswersMixin";
import BackButton from "@/components/Pages/TheoryQuestions/BackButton.vue";
import FavoriteActionMixin from "@/mixins/FavoriteActionMixin";
import FavoriteButton from "@/components/FavoriteButton.vue";
import QuestionHint from "@/components/QuestionHint.vue";
import NextButton from "@/components/NextButton.vue";

const TheoryQuestionGroupModule = namespace("theory-question-groups");
const StudentEducationModule = namespace("student-education");
const TheoryQuestionsModule = namespace("theory-questions");
const StudentQuestionAnswersModule = namespace("student-question-answers");

enum TheoryQuestionGroupEnum {
  INCORRECTLY_ANSWERED = "INCORRECTLY_ANSWERED",
  REMAINING = "REMAINING",
  READY_FOR_EXAM = "READY_FOR_EXAM",
  CORRECTLY_ANSWERED = "CORRECTLY_ANSWERED",
}

@Options({
  components: {
    NextButton,
    QuestionHint,
    FavoriteButton,
    BackButton,
    Question,
    DefaultLayout,
  },
})
export default class TheoryQuestionBox extends mixins(NavigationMixin, QuestionAnswersMixin, FavoriteActionMixin) {
  @Prop()
  public studentEducation!: any;
  @Prop()
  public theoryQuestionGroupId!: any;
  @Prop()
  public selectedQuestionId!: any;

  @TheoryQuestionsModule.Action("resetSave")
  public resetTheoryQuestionsQuestion: any;

  @TheoryQuestionsModule.Action("setMarkFavouriteSuccess")
  public setMarkFavouriteSuccessAction: any;

  @StudentQuestionAnswersModule.Action("create")
  public answerAction: any;

  @StudentQuestionAnswersModule.Action("resetSave")
  public resetAnswerQuestion: any;

  @StudentQuestionAnswersModule.Getter("getSuccess")
  public answerSuccess: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public loadingAnswer: any;

  @TheoryQuestionGroupModule.Action("findQuestionsByGroupPaged")
  public findQuestionsByGroupPagedAction: any;

  @TheoryQuestionGroupModule.Action("resetQuestions")
  public resetQuestionsAction: any;

  @TheoryQuestionGroupModule.Getter("getGroups")
  public groups: any;

  @TheoryQuestionGroupModule.Getter("getQuestions")
  public questions: any;

  @TheoryQuestionGroupModule.Getter("getTotal")
  public total: any;

  @TheoryQuestionGroupModule.Getter("getIsLoading")
  public questionsLoading: any;

  private showSolutionButton = false;
  private showCorrectAnswers = false;
  protected question: any = {};

  get loading(): boolean {
    return this.loadingAnswer || this.questionsLoading;
  }

  get questionsBoxLabel(): string {
    let label = "";
    switch (this.theoryQuestionGroupId) {
      case TheoryQuestionGroupEnum.INCORRECTLY_ANSWERED:
        label = String(this.$t("general.false"));
        break;
      case TheoryQuestionGroupEnum.REMAINING:
        label = String(this.$t("general.still_opened"));
        break;
      case TheoryQuestionGroupEnum.READY_FOR_EXAM:
        label = String(this.$t("general.ready_for_examination"));
        break;
      case TheoryQuestionGroupEnum.CORRECTLY_ANSWERED:
        label = String(this.$t("general.one_time_correct"));
        break;
    }
    return label;
  }

  private goToNext(): void {
    if (this.theoryQuestionGroupId == TheoryQuestionGroupEnum.INCORRECTLY_ANSWERED) {
      if (this.questionIsCorrect === true) {
        this.offset--;
        this.questionIsCorrect = undefined;
      }
    } else if (
      this.theoryQuestionGroupId == TheoryQuestionGroupEnum.READY_FOR_EXAM ||
      this.theoryQuestionGroupId == TheoryQuestionGroupEnum.CORRECTLY_ANSWERED
    ) {
      if (this.questionIsCorrect === false) {
        this.offset--;
        this.questionIsCorrect = undefined;
      }
    }

    this.offset++;

    if (this.offset >= this.total && this.theoryQuestionGroupId != TheoryQuestionGroupEnum.REMAINING) {
      this.offset = 0;
    }

    this.showCorrectAnswers = false;
    this.reset();
    this.loadQuestions();
  }

  private onMarkFavourite(favourite: boolean): void {
    this.question.favourite = favourite;
  }

  private loadQuestions(): void {
    this.findQuestionsByGroupPagedAction({
      group: this.theoryQuestionGroupId,
      offset: this.offset,
      limit: 1,
      studentEducationId: this.studentEducation,
      hideSuccessMessage: true,
    });
  }

  private showSolutions(): void {
    this.showSolutionButton = false;
    this.showCorrectAnswers = true;
  }

  @Watch("theoryQuestionGroupId", { immediate: true, deep: true })
  private onTheoryQuestionGroupChange(theoryQuestionGroupId: any): void {
    if (theoryQuestionGroupId) {
      this.loadQuestions();
    }
  }

  @Watch("questions", { immediate: true, deep: true })
  private onQuestionsChange(): void {
    if (this.questions) {
      this.question = this.questions[0];
    }
  }

  @Watch("total", { immediate: true, deep: true })
  private onTotalChange(total: any, oldTotal: any): void {
    if (!total || !oldTotal || this.theoryQuestionGroupId != TheoryQuestionGroupEnum.REMAINING) {
      return;
    }

    if (total < oldTotal) {
      this.offset--;
    }

    if (total > oldTotal) {
      this.offset++;
    }
  }

  private reset(): void {
    this.resetTheoryQuestionsQuestion();
  }

  public beforeUnmount(): void {
    this.reset();
    this.resetQuestionsAction();
  }

  public get showNextBtn(): boolean {
    if (this.theoryQuestionGroupId != TheoryQuestionGroupEnum.REMAINING) {
      return this.total > 1;
    }

    return this.offset < this.total - 1;
  }
}


import { Options, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import TheoryQuestionBox from "@/components/Pages/TheoryBoxes/TheoryQuestionBox.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: { ForwardHeader, TheoryQuestionBox, DefaultLayout },
})
export default class TheoryQuestionBoxes extends Vue {
  private studentEducation: number | null = null;
  private theoryQuestionGroup = "";

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    this.theoryQuestionGroup = newVal.params.id;
    this.studentEducation = newVal.params.studentEducation;
  }
}
